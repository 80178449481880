import { Button, Divider, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../core/store/hooks";
import { useEffect, useState } from "react";
import { UUID } from "@wattsonelements/front-fdk/dist/models/type.models";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export const TicketCategorySelect = () => {
  const { t } = useTranslation("ticket");

  const form = useFormContext()
  const [selectedParentCategory, setSelectedParentCategory] = useState<UUID>()
  const categories = useAppSelector((state) => state.tickets.categories).filter(
    (cat: any) => cat.allow_creation === true && cat.parent_category !== null
  );
  const parentCategories = useAppSelector((state) => state.tickets.categories).filter(
    (cat: any) => cat.parent_category === null
  );

  useEffect(() => {
    if (parentCategories.length > 0 && !selectedParentCategory) {
      setSelectedParentCategory(parentCategories[0].id)
    }
  }, [parentCategories])

  return <div className="flex flex-col gap-2">
    <div className="flex flex-wrap gap-2">
      {
        parentCategories.map((c, i) => <Button
          size="large"
          onClick={() => {
            setSelectedParentCategory(c.id)
          }}
          variant={(selectedParentCategory === c.id) ? "outlined" : "text"}
          color={(selectedParentCategory === c.id) ? "secondary" : "primary"}
          key={c.slug}>
          <span className="text-base">{c.label}</span>

        </Button>)
      }

    </div>
    <Divider />
    <Controller
      name="category"
      control={form.control}
      render={({ field }) => {
        return <div className="flex flex-wrap gap-2">
          {
            categories.filter(c => c.parent_category === selectedParentCategory && c.allow_creation).map(c => {

              const isSelected = field.value === c.id

              return <Button
                key={c.id}
                onClick={() => {
                  field.onChange(c.id)
                  field.onBlur()
                }}
                className="flex gap-3"
                color={"primary"} variant={
                  classNames({
                    "contained": isSelected,
                    "text": !isSelected
                  }) as any
                }>
                <img
                  alt={c.label}
                  className={classNames("h-5 w-5 ", {
                    "invert": !isSelected
                  })}
                  src={c.icon_url}
                />
                {c.label}
              </Button>
            })
          }
        </div>
      }}
    />


  </div>
}