import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import { TicketPriority } from "@wattsonelements/front-fdk/dist/constants/ticket.constants";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const PrioritySwitch = () => {
  const { t } = useTranslation("ticket");
  const form = useFormContext()

  return <Controller
    name="priority"
    control={form.control}
    render={({ field }) => <Tooltip placement="left" arrow title={t("form.intermediateToolTip")}>
      <FormControlLabel
        control={
          <Switch
            checked={field.value === TicketPriority.INTERMEDIATE}
            onChange={(e) => {
              field.onChange(e.target.checked ? TicketPriority.INTERMEDIATE : TicketPriority.NORMAL)
            }}
            onBlur={field.onBlur}
            color="error"
          />
        }
        label={t("form.intermediate")}
      />
    </Tooltip>}
  />
}